<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="outstocks" ref="dt" rowGroupMode="rowspan" groupRowsBy="factory" sortMode="single"
                    sortField="factory" :sortOrder="1" responsiveLayout="scroll" :lazy="false"
                    :virtualScrollerOptions="{ itemSize: 40 }" :loading="loading" :tableStyle="styleHeight"
                    :scrollHeight="sHeight">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <Calendar placeholder="统计时间" v-model="storeTime" dateFormat="yy-mm-dd" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button type="button" label="查询" class="mb-2"
                                        @click="customFilter();" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-external-link" label="导出" @click="exportData()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                        class="p-button-outlined mb-2" @click="clearFilter1()" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column header="#" :headerStyle="{width:'3em'}">
                        <template #body="slotProps">
                            {{slotProps.data.rowindex}}
                        </template>
                    </Column>
                    <Column header="工厂" field="factory">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.factory, disabled: false }">{{slotProps.data.factory}}</label>
                        </template>
                    </Column>
                    <Column header="平台MPN" field="mpn">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.mpn, disabled: false }">{{slotProps.data.mpn}}</label>
                        </template>
                    </Column>
                    <Column header="GIGA_SKU" field="sku">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.sku, disabled: false }">{{slotProps.data.sku}}</label>
                        </template>
                    </Column>
                    <Column header="首次下单月" field="firstsaletime">
                        <template #body="slotProps">
                            <label class="oneline3"
                                v-tooltip.top="{ value: slotProps.data.firstsaletime, disabled: false }">{{slotProps.data.firstsaletime}}</label>
                        </template>
                    </Column>
                    <Column header="产品名称" field="name">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                        </template>
                    </Column>
                    <Column header="产品分类" field="productcategory">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.productcategory, disabled: false }">{{slotProps.data.productcategory}}</label>
                        </template>
                    </Column>
                    <Column header="实际库存可售天数" field="actualcansaleday">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.actualcansaleday, disabled: false }">{{slotProps.data.actualcansaleday}}</label>
                        </template>
                    </Column>
                    <Column header="理论库存可售天数" field="prospectcansaleday">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.prospectcansaleday, disabled: false }">{{slotProps.data.prospectcansaleday}}</label>
                        </template>
                    </Column>
                    <Column header="14天实销速度" field="fourteensalespeed">
                        <template #body="slotProps">
                            <label class="oneline1"
                                v-tooltip.top="{ value: slotProps.data.fourteensalespeed, disabled: false }">{{slotProps.data.fourteensalespeed}}</label>
                        </template>
                    </Column>
                    <Column header="在产库存" field="processquantity">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.processquantity, disabled: false }">{{slotProps.data.processquantity}}</label>
                        </template>
                    </Column>
                    <Column header="在途库存" field="shipmentquantity">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.shipmentquantity, disabled: false }">{{slotProps.data.shipmentquantity}}</label>
                        </template>
                    </Column>
                    <Column header="实际库存" field="allquantity">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.allquantity, disabled: false }">{{slotProps.data.allquantity}}</label>
                        </template>
                    </Column>
                    <Column header="保证金未发" field="subscribequantity">
                        <template #body="slotProps">
                            <label class="oneline3"
                                v-tooltip.top="{ value: slotProps.data.subscribequantity, disabled: false }">{{slotProps.data.subscribequantity}}</label>
                        </template>
                    </Column>
                    <Column header="理论库存" field="prospectquantity">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.prospectquantity, disabled: false }">{{slotProps.data.prospectquantity}}</label>
                        </template>
                    </Column>
                    <Column header="圈货个数" field="reservesalequantity">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.reservesalequantity, disabled: false }">{{slotProps.data.reservesalequantity}}</label>
                        </template>
                    </Column>
                    <Column header="实销个数" field="salequantity">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.salequantity, disabled: false }">{{slotProps.data.salequantity}}</label>
                        </template>
                    </Column>
                    <Column header="14天销售数量" field="fourteensalenum">
                        <template #body="slotProps">
                            <label class="oneline1"
                                v-tooltip.top="{ value: slotProps.data.fourteensalenum, disabled: false }">{{slotProps.data.fourteensalenum}}</label>
                        </template>
                    </Column>
                    <Column header="平均售价" field="averageprice">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.averageprice, disabled: false }">{{slotProps.data.averageprice}}</label>
                        </template>
                    </Column>
                    <Column header="销售总额" field="allsalemoney">
                        <template #body="slotProps">
                            <label class="oneline2"
                                v-tooltip.top="{ value: slotProps.data.allsalemoney, disabled: false }">{{slotProps.data.allsalemoney}}</label>
                        </template>
                    </Column>
                    <Column header="出库总成本" field="outprice">
                        <template #body="slotProps">
                            <label class="oneline3"
                                v-tooltip.top="{ value: slotProps.data.outprice, disabled: false }">{{slotProps.data.outprice}}</label>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
<style type="text/css">
    .oneline {
        width: 100px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .oneline1 {
        width: 40px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .oneline3 {
        width: 70px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .oneline2 {
        width: 50px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import commonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '736';
            const allRecords = ref(0);
            const allRecords2 = ref(0);
            return {
                confirm,
                currentMenuId,
                allRecords,
                allRecords2
            };
        },
        data() {
            return {
                matchModes: [],
                timeMatchModes: [],
                numMatchModes: [],
                butLoading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                filters1: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                first: 0,
                listTitle: '日报销售SKU统计',
                selectedKey: {
                    736: true
                },
                expandedKey: null,
                storeTime: '',
                outstocks: [],
                loading: false,
                nodes: [],
                items: [{
                    label: '统计操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportData();
                        }
                    }, ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        created() {
            MessageTip.initMsg();
            this.storeTime = commonJs.GetCurrentDate();
            this.loadMenuTree();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
            this.loadData(1);
        },
        methods: {
            clearFilter1() {
                this.storeTime = '';
            },
            loadMenuTree() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '731',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usertreemenulist(where:" + JSON.stringify(listwhere) +
                    "){key label data nodetype icon leaf children {key label data nodetype parentid parenturl icon leaf} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.nodes = jsonData.data.usertreemenulist;
                        if (this.$route.query.nodeid && this.$route.query.nodeid != '' && this.nodes) {
                            this.selectedKey = {};
                            this.selectedKey[this.$route.query.nodeid] = true;
                            this.expandedKey = {};
                            this.expandedKey[this.currentMenuId] = true;
                            var isFind = false;
                            for (var i = 0; i < this.nodes.length; i++) {
                                for (var j = 0; j < this.nodes[i].children.length; j++) {
                                    if (this.nodes[i].children[j].key == this.$route.query.nodeid) {
                                        isFind = true;
                                        this.queryPlanObj = {
                                            name: this.nodes[i].children[j].label,
                                            id: this.nodes[i].children[j].key,
                                            parentid: this.currentMenuId,
                                            queryconfig: this.nodes[i].children[j].data,
                                        };
                                        if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig !=
                                            '') {
                                            this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                        }
                                        this.listTitle = this.nodes[i].children[j].label;
                                        break;
                                    }
                                }
                                if (isFind) {
                                    break;
                                }
                            }
                        }
                    }
                });
            },
            onNodeSelect(node) {
                console.log(node);
                setTimeout(() => {
                    var currentKey = '';
                    if (this.selectedKey) {
                        for (var key in this.selectedKey) {
                            currentKey = key;
                            break;
                        }
                    }
                    if (node.nodetype == '1') {
                        this.queryPlanObj = {
                            name: '',
                            id: '-1',
                            parentid: this.currentMenuId,
                            queryconfig: '',
                        };
                        console.log('node.key:' + node.key);
                        console.log('selectedKey:');
                        console.log(this.selectedKey);
                        console.log('currentKey:' + currentKey);
                        if (node.key != this.currentMenuId) {
                            window.location.href = node.data;
                        } else {
                            this.clearFilter1();
                            this.clearFilter2();
                            this.loadData(1);
                            this.listTitle = "日报销售SKU统计";
                        }
                    }
                    if (node.nodetype == '2') {
                        if (this.currentMenuId != node.parentid) {
                            window.location.href = node.parenturl + "?nodeid=" + node.key;
                        } else {
                            this.queryPlanObj = {
                                name: node.label,
                                id: node.key,
                                parentid: this.currentMenuId,
                                queryconfig: node.data,
                            };
                            console.log('expandedKey:');
                            console.log(this.expandedKey);
                            if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig != '') {
                                console.log('queryconfig:');
                                console.log(this.queryPlanObj.queryconfig);
                                this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                this.loadData(1);
                                this.listTitle = node.label;
                            }
                        }
                    }
                }, 20);
            },
            customFilter() {
                this.loading = true;
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.loadData(page + 1);
            },
            loadData(v_page) {
                if (this.storeTime == '') {
                    MessageTip.warnmsg('请选择统计时间');
                    return;
                }
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: -1,
                    conditions: [{
                        name: 'storetime',
                        value: this.storeTime,
                        operation: '='
                    }]
                };
                for (var key in this.filters1) {
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{daysalestatlist(where:" + JSON.stringify(listwhere) +
                    "){id rowindex factory mpn sku firstsaletime name productcategory actualcansaleday prospectcansaleday fourteensalespeed processquantity shipmentquantity allquantity subscribequantity prospectquantity reservesalequantity salequantity fourteensalenum averageprice allsalemoney outprice }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.outstocks = jsonData.data.daysalestatlist;
                    }
                });
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            exportData() {
                this.$refs.dt.exportCSV();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            'AppFooter': AppFooter,
        }
    }
</script>